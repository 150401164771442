import icon from '../img/error.png';

const Error = () => {
    return (
        <div className="Error">
            <img src={icon} alt="error icon" />
            <h1>404 Page not found!</h1>
        </div>
    )
}



export default Error;